<template>
  <PDialog 
    :title="$tk('Common.General.Details')" 
    :loading="isLoading"
    classes="left-0 md:left-auto md:w-3/5"     
    @close="$emit('close')"
  >
    <PDialogContent>      

      <template v-if="entry">

        <div class="p-4 bg-green-100 rounded-lg">

          <div class="flex flex-col space-y-4">

              <div class="flex space-x-4">
                
                <PText
                  class="w-1/2"        
                  :label="this.$t('Common.General.Date')"
                >
                  {{ entry.createdLocal | dateAndTime }}
                </PText>

                <PText
                  class="w-1/2"        
                  :label="this.$t('Common.General.Address')"
                >
                  {{ entry.remoteAddress || "-" }}
                </PText>

              </div>

              <div class="flex space-x-4">
                
                <PText
                  class="w-1/2"        
                  :label="this.$t('Common.General.Url')"
                >
                  {{ entry.url || "-" }}
                </PText>

                <PText
                  class="w-1/2"        
                  :label="this.$t('Common.General.Customer')"
                >
                  {{ entry.customerId || "-" }}
                </PText>

              </div>

              <div class="flex space-x-4">
                
                <PText
                  class="w-1/2"        
                  :label="this.$t('Common.General.Location')"
                >
                  {{ entry.locationId || "-" }}
                </PText>

                <PText
                  class="w-1/2"        
                  :label="this.$t('Common.General.User')"
                >
                  {{ entry.userId || "-" }}
                </PText>

              </div>

              <div class="flex space-x-4">
                
                <PText
                  class="w-1/2"        
                  :label="this.$t('Common.General.Category')"
                >
                  {{ entry.category || "-" }}
                </PText>

              </div>

          </div>

        </div>

        <div class="p-4 mt-4 text-sm bg-white rounded">
          
          <div class="flex flex-col space-y-4">

            <PText :label="this.$t('Common.General.Message')">
              {{ entry.message || "-" }}
            </PText>

            <PText :label="this.$t('Common.General.Error')">
              {{ entry.error || "-" }}
              
              <div class="mt-4" v-if="entry.stackTrace">
                <a 
                  href 
                  class="flex items-center text-green-500 underline"
                  @click.prevent="stackTraceVisible = !stackTraceVisible">
                  <div class="mr-1" v-html="$tk('Common.General.ShowDetails')"></div>
                  <FontAwesomeIcon :icon="['far', stackTraceVisible ? 'chevron-up' : 'chevron-down']" />
                </a>
                <div class="mt-1 font-normal" v-if="stackTraceVisible">
                  {{ entry.stackTrace }}
                </div>
              </div>
            </PText>
            

          </div>

        </div>

      </template>

    </PDialogContent>
    
    <PDialogActions>

      <PButton 
        @click="$emit('close')" 
        color="secondary"            
      >
        <span v-html="$tk('Common.Actions.Close')"></span>
      </PButton>

    </PDialogActions>

  </PDialog>
</template>

<script>

import http from "@/http"

export default {
  
  props: {
    id: {
      type: String,
      required: true
    }
  },
  
  data () {
    return {
      entry: null,
      stackTraceVisible: false
    }
  },

  async created () {
    this.isLoading = true
    try {
      this.entry = await http.get("Log", { params: { id: this.id }})
    } catch (error) {
      this.$appInsights.trackException({exception: error })
    }
    this.isLoading = false
  }

}
</script>
